import styled from '@emotion/styled'
import { Star1 } from 'assets/images';

export const StarsWinContainer = styled.div`
  position: relative;
  width: 35px;
  height: 35px;
`;

export const StarItemContainer = styled.div`
  position: absolute;
  animation: ${(props) => `starswin${props.hey}`} ${(props) => `${props.time}`}s
    infinite;
  animation-timing-function: ease-in-out;
  animation-delay: ${props => props.delay}s;
  /* color: ${(props) => props.color}; */
  color: transparent;
  width: 65px;
  height: 65px;
  background: ${props => `url(${props.image})`};
  /* background: url(${Star1}); */
  background-size: 100% 100%;
  @keyframes ${(props) => `starswin${props.hey}`} {
    0% {
      left: 0px;
      bottom: 0px;
    }
    80% {
      opacity: 0.7;
    }
    100% {
      left: ${(props) => props.left}px;
      bottom: ${(props) => props.bottom}px;
      opacity: 0;
    }
  }
`;